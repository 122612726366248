.gm-style div {
  border-radius: 24px !important;
}

@keyframes updown {
  0% {
    transform: translateY(-30%);
  }

  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-30%);
  }
}

@keyframes eatme {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

.mark-clicked {
  color: rgb(107, 107, 107) !important;
  animation: updown 0.5s ease infinite;
}

.mark-over:hover {
  transform: scale(1.3);
  transition: 0.2s;
  transition-timing-function: ease;
}

.mark-over {
  cursor: pointer;
}

.list-over {
  animation: eatme 0.5s ease infinite;
  color: rgb(255, 136, 0);
}

.front-box {
  z-index: 10000;
  border-radius: 8px;
}

.input-autocomplete {
  width: 100%;
  line-height: 45px;
  border-radius: 16px;
  border: 1px solid #b1b1b1;
  padding-left: 16px;
  font-size: 16px;

  font-size: 16px;
}

a {
  cursor: pointer;
}

.background-layer {
  background-color: #e9e9e9;
  width: 100vw !important;
  height: 100vh !important;
}

.eat-button {
  background-color: white !important;
  box-shadow: none !important;
  border: 1px solid #d4d4d4 !important;
  color: #747373 !important;
}

.addmenu-background {
  background-color: #e9e9e9;

  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}

.eat-button:hover {
  background-color: #e4e4e4 !important;
  color: #747373 !important;
  border-color: none !important;
  transform: scale(1.1);
  transition: 0.3s;
  transition-timing-function: ease;
}

.large-input {
  line-height: 150px !important;
}

.nav-shadow {
  box-shadow: 1px 2px 5px #e9e9e9 !important;
}

.MuiDialog-paper {
  height: "500px";
}

.MuiPaper-root {
  height: "500px";
}

.image-crop {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.MuiMasonry-root {
  margin: 0 !important;
}

.transition-hover {
  transition: all 0.3s ease !important;
}

.thai {
  font-family: "Kanit", sans-serif !important;
}

.english {
  font-family: "Roboto", sans-serif;
}
